<template>
  <div id="FaseModal">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="justify-space-between py-2 px-3">
        <span class="title-page body-1">Fase</span>
          <v-btn
          @click="fechar()"
          icon
          dark
          color="#F2F6F7"
          >
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
      </v-card-title>

      <v-card-text class="pt-1 pb-10">
        <v-container>
          <v-row>
            <v-col class="px-0">
              <v-form 
              ref="form"
              v-model="valid">

                <v-text-field
                  v-model="dados.nome_fase"
                  class="mb-0"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="Nome da fase"
                  placeholder="Nome da fase"
                  filled
                  autofocus
                  required
                  maxlength="100"
                  dense
                  :rules="nomeRules"
                ></v-text-field>

                <v-text-field
                  v-model="dados.porcentagem"
                  class="mt-n2 mb-0"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="Porcentagem"
                  placeholder="Porcentagem"
                  filled
                  required
                  dense
                  :rules="porcentagemRules"
                ></v-text-field>

              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
        @click="fechar()"
        class="mr-4 btn caption font-weight-medium"
        color="primary"
        text
        >
          Cancelar
        </v-btn>

        <v-btn
        :disabled="!valid"
        @click="validate()"
        class="btn white--text caption font-weight-medium"
        color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import { COR_PRINCIPAL } from "../../../services/constantes";
import { API } from "../../../services/API"
import store_site from "../../../store/store_site"

export default {
  name: "FaseModal",
  props: {
    dialog_FaseModal : Boolean, 
    dados_editar : Object},

  components: {
  },

  data() {
    return {

      COR_PRINCIPAL     : COR_PRINCIPAL,
      API               : API,
      store_site        : store_site,

      dados: {
        nome_fase: null,
        porcentagem: null,
      },

      valid: true,
      form: false,

      nomeRules: [
        (value) => !!value || "O nome é obrigatório",
        (value) =>
          (value && value.length <= 100) ||
          "O nome deve ter menos do que 100 caracteres",
      ],
      porcentagemRules:[
        (value) => !!value || "A porcentagem é obrigatória",
        (value) => value >= 0 && value < 101 || "A porcentagem deve ter entre 0 e 100",
        // (value) =>
        // (!value && Number(value).length <= 3) || "A porcentagem deve ter até 3 caracteres" + (!value && Number(value).length <= 3),
        (value) => 
        ( !value ||  /^\d+$/.test(value)) || 'A porcentagem não é válida',
      ],
    };
  },

  mounted(){
    if(this.dados_editar.acao === 'editar'){
      this.dados = {...this.dados_editar}
      this.dados.porcentagem = String(this.dados_editar.porcentagem)
    } 

  },

  methods: {
    fechar() {
      this.$emit('update:dialog_FaseModal', null);
    },
    async validate() {
      const lb_valido = this.$refs.form.validate();

      if (lb_valido) {
        this.loading = true;

        this.dados.cod_empreendimento = Number(this.$route.params.cod_empreendimento)
        var response
        if(this.dados?.acao === 'editar'){
          response = await API.put(`/fase_empreendimento/${this.dados.cod_empreend_fase}`, JSON.stringify(this.dados))
        }
        else{
          response = await API.post(`/fase_empreendimento`, JSON.stringify(this.dados))
        }
        
        if (response.data.result.message == 'success') {
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 1500;
          this.store_site.alert_msg = response.data.result.result.trim();
          this.store_site.alert = true;
        } else {
          this.store_site.alert_cor = this.store_site.alert_cor_vermelho;
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg = response.data.result.trim();
          this.store_site.alert = true;
        }
        this.loading = false;
        this.$emit('fases', [])

        this.fechar()
      }
    },
  
  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.btn {
  width: 100px;
}
</style>