<template>
  <div id="Contabil">
    <!-- TÍTULO DA PÁGINA -->

    <v-toolbar-title v-bind:style="{ 'color': COR_SUBTITULO }"
      class="d-flex align-center justify-space-between body-1 font-primary ml-2">
      <p class="font-primary font-weight-bold font-weight-medium body-1" v-bind:style="{ 'color': COR_SUBTITULO }">
        Contábil
      </p>

      <v-btn @click="abreEditar()" class="btn mr-1 pr-6 " elevation="0">
        <v-icon class="btn-icon mr-1" color="green accent-4">mdi-pencil-outline</v-icon>
        <span class="caption font-weight-medium" v-bind:style="{ 'color': COR_SUBTITULO }">Editar</span>
      </v-btn>
    </v-toolbar-title>
    <!-- FIM TÍTULO DA PÁGINA -->

    <!-- CONTAINER "LABEL" -------------------------------------------------------->
    <v-container class="">
      <v-row>
        <v-col class="d-flex pa-0">
          <span class="header-text body-2 font-weight-medium mr-4">Código contábil empreendimento: <span
              class="font-weight-bold body-1" v-bind:style="{ 'color': COR_SUBTITULO }">{{
                  store_Empreendimento.empreendimento_edicao.codigo_contabil
              }}</span></span>
        </v-col>
      </v-row>
    </v-container>
    <!-- FIM CONTAINER "LABEL" -------------------------------------------------------->

    <v-container class="container mt-6 mx-0 pa-0 ">
      <!-- TABELA -------------------------------------------------------->
      <v-data-table
        class="tabela rounded-lg mt-4 mb-3"
        :items="array_contabil"
        style="white-space: nowrap"
        :headers="headers_contabil"
        :loading="loading"
        :items-per-page="10"
        hide-default-header
        hide-default-footer
        loading-text="Carregando...  aguarde..."
        no-data-text="Nenhum Registro Encontrado"
        no-results-text="Nenhum Registro Encontrado">
        <template v-slot:top>
          <v-toolbar flat class="mb-n2 rounded-lg">
            <p class="font-primary font-weight-bold body-1 text-center mt-1" v-bind:style="{ 'color': COR_SUBTITULO }">
              Contábil (Integração)
            </p>
          </v-toolbar>
        </template>

        <template v-slot:header>
          <thead>
            <tr>
              <th v-for="h in headers_contabil" :key="h.value" :class="h.class" class="py-2">
                <span>{{ h.text }}</span>
              </th>
            </tr>
          </thead>
        </template>

        <template #item="{ item }">
          <tr>
            <td v-bind:style="{ 'color': COR_SUBTITULO }">
              {{ item.cont_descricao }}
            </td>

            <td>
              {{ item.historico }}
            </td>

            <td>
              <span class="d-flex align-center">
                {{ item.cont_conta_credito }}
              </span>
            </td>
            <td>
              <v-checkbox
                readonly
                color="primary"
                class="ml-1"
                v-model="item.conta_credito_lote"
                :value="item.conta_credito_lote == 'N' ? true : false">
              </v-checkbox></td>

            <td>
              <span class="d-flex align-center">
                {{ item.cont_conta_debito }}
              </span>
            </td>
            <td>
              <v-checkbox
                 readonly
                 color="primary"
                 class="ml-1"
                 v-model="item.conta_debito_lote"
                 :value="item.conta_debito_lote == 'N' ? true : false"></v-checkbox>
            </td>
            <td>
              <v-btn
                @click="store_Empreendimento.array_contabil_detalhes = {...item}; store_Empreendimento.dialogContabilEdicao = true;"
                color="grey" dark icon>
                <v-icon class="btn-icon">mdi-pencil-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <!-- FIM TABELA -->

      <v-container
        v-if="store_Empreendimento.dialogContabil">
        <v-dialog
          v-model="store_Empreendimento.dialogContabil"
          transition="dialog-bottom-transition"
          max-width="400"
          class="pa-0"
          persistent>
          <ContabilModal 
            ref="ContabilModal"
            :_codigo_contabil.sync="store_Empreendimento.empreendimento_edicao.codigo_contabil" />
        </v-dialog>
      </v-container>

      <v-container
        v-if="store_Empreendimento.dialogContabilEdicao">
        <v-dialog
          v-model="store_Empreendimento.dialogContabilEdicao"
          transition="dialog-bottom-transition"
          max-width="550"
          class="pa-0"
          persistent>
          <ContabilEdicaoModal 
            ref="ContabilEdicaoModal"
            :_array_contabil.sync="array_contabil"
          />
        </v-dialog>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import { COR_SUBTITULO } from "../../../services/constantes";
import store_Empreendimento from "../store_Empreendimento";
import ContabilModal from "../Contabil/ContabilModal.vue";
import ContabilEdicaoModal from "../Contabil/ContabilEdicaoModal.vue";

export default {
  name: "Contabil",

  components: {
    ContabilModal,
    ContabilEdicaoModal,
  },

  data() {
    return {
      store_Empreendimento   : store_Empreendimento,

      cod_empreendimento     : this.$route.params,
      array_contabil         : [],
      array_contabil_detalhes: null,
      COR_SUBTITULO          : COR_SUBTITULO,
      loading                : false,

      /* TITULOS TABELA CONTABIL */
      headers_contabil: [
        { text: "Descrição", value: "descricao", class: 'title-text' },
        { text: "Histórico", value: "historico", class: 'title-text' },
        { text: "Conta Crédito", value: "conta_credito", class: 'title-text' },
        { text: "Usar do lote", value: "usar", class: 'title-text' },
        { text: "Conta Débito", value: "conta_debito", class: 'title-text' },
        { text: "Usar do lote", value: "usar2", class: 'title-text' },
        { text: "" },
      ],

      /* MENU EDIÇÃO (BUTTON DOTS) */
      items: [
        {
          title: "Editar",
        },
        {
          title: "Excluir",
        },
      ],

    };
  },

  methods:{
    abreEditar() {
      this.store_Empreendimento.dialogContabil = true
    }
  },

  async mounted() {
    this.array_contabil = [];
    const la_Contabil = await this.store_Empreendimento.getContabil({ cod_empreendimento: this.$route.params.cod_empreendimento });
    this.array_contabil = la_Contabil;
  },

};
</script>

<style scoped>
#Contabil {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #Contabil {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#Contabil::-webkit-scrollbar {
  width: 5px;
}

#Contabil::-webkit-scrollbar-button {
  padding: 1px;
}

#Contabil::-webkit-scrollbar-thumb {
  background: #CECECE;
  border-radius: 50px;
}

#Contabil::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7;
  height: 150px;
}

@media(max-width: 599px) {
  #Contabil::-webkit-scrollbar {
    width: initial;
  }

  #Contabil::-webkit-scrollbar-button {
    padding: initial;
  }

  #Contabil::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #Contabil::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  }
}

.container {
  max-width: 100%;
}

.sticky-table-footer-desktop td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 20px !important;
}
</style>
