<template>
  <div id="ContabilEdicaoModal">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color': COR_PRINCIPAL }" class="justify-space-between py-2 px-3">
        <span class="title-page body-1">{{ store_Empreendimento.array_contabil_detalhes.cont_descricao }}</span>
        <v-btn @click="store_Empreendimento.dialogContabilEdicao = false" icon dark color="#F2F6F7">
          <v-icon large class="title">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-1 pb-10">
        <v-container>
          <v-row>
            <v-col class="px-0">
              <v-form ref="form" v-model="valid">

                <v-row class="mt-n4">
                  <v-col cols="12" sm="12" class="pt-2 px-2 pb-6">
                    <v-text-field v-model="store_Empreendimento.array_contabil_detalhes.historico" class="mb-0" light loader-height="1" background-color="#FFF"
                      label="Histórico" placeholder="Histórico" filled required dense
                      :rules="historicoRules"></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mt-n4">
                  <v-col cols="8" sm="8" class="pt-2 px-2 pb-0">
                    <v-text-field v-model="store_Empreendimento.array_contabil_detalhes.cont_conta_credito" class="mt-n2 mb-0" light loader-height="1"
                      background-color="#FFF" label="Conta crédito" placeholder="Conta crédito" filled required dense
                      :rules="contaRules"></v-text-field>
                  </v-col>

                  <v-col cols="4" sm="4" class="pt-2 px-2 pb-6">
                    <v-select v-model="store_Empreendimento.array_contabil_detalhes.conta_credito_lote" class="mt-n2 mb-0" light loader-height="1"
                      background-color="#FFF" style="margin-top: -7px;" height="52px" auto-select-first solo filled
                      dense :items="array_cont_conta_credito">
                    </v-select>
                  </v-col>
                </v-row>

                <v-row class="mt-n4">
                  <v-col cols="8" sm="8" class="pt-2 px-2 pb-0">
                    <v-text-field v-model="store_Empreendimento.array_contabil_detalhes.cont_conta_debito" class="mt-n2 mb-0" light loader-height="1"
                      background-color="#FFF" label="Conta débito" placeholder="Conta débito" filled required dense
                      :rules="contaRules">
                    </v-text-field>
                  </v-col>

                  <v-col cols="4" sm="4" class="pt-2 px-2 pb-6">
                    <v-select v-model="store_Empreendimento.array_contabil_detalhes.conta_debito_lote" class="mt-n2 mb-0" light loader-height="1"
                      background-color="#FFF" style="margin-top: -7px;" height="52px" auto-select-first solo filled
                      dense :items="array_cont_conta_credito">
                    </v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn @click="store_Empreendimento.dialogContabilEdicao = false" class="mr-4 btn caption font-weight-medium"
          color="primary" text>
          Cancelar
        </v-btn>

        <v-btn :loading="loading" :disabled="!valid" @click="validate()"
          class="btn white--text caption font-weight-medium" color="primary accent-4">
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import { COR_PRINCIPAL } from "../../../services/constantes";
import store_Empreendimento from "../store_Empreendimento";
import store_site from "../../../store/store_site";


export default {
  name: "ContabilEdicaoModal",

  props: {
    _array_contabil: {
      type: Array,
      default: () => ([]),
    }
  },

  components: {
  },

  data() {
    return {
      store_Empreendimento: store_Empreendimento,

      store_site              : store_site,
      contabil                : {},
      array_contabil          : [],
      loading                 : false,
      array_cont_conta_credito: [
        {
          text: "Sim",
          value: "S"
        },
        {
          text: "Não",
          value: "N"
        }
      ],

      COR_PRINCIPAL : COR_PRINCIPAL,
      valid         : true,
      form          : false,

      historicoRules: [
        (value) => !!value || "O histórico é obrigatório",
        (value) => /^\d+$/.test(value) || 'O histórico não é válido',
        (value) =>
          (value && value.length <= 10) ||
          "O histórico deve ter menos do que 10 caracteres",
      ],
      contaRules: [
        (value) => !!value || "A conta é obrigatória",
        (value) => /^\d+$/.test(value) || 'A conta não é válida',
        (value) =>
          (value && value.length <= 10) ||
          "A conta deve ter menos do que 10 caracteres",
      ],
    };
  },

  methods: {
    async validate() {
      const lb_valido = this.$refs.form.validate();

      if (lb_valido) {

        var lo_JSON = {
          ...this.store_Empreendimento.array_contabil_detalhes,
        };

        var ls_Res;
        this.loading = true;

        ls_Res = await this.store_Empreendimento.ContabilPut(lo_JSON);

        if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
          this.store_site.alert_cor     = "#00A000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg     = ls_Res.result.result.trim();
          this.store_site.alert         = true;

          const lo_Item = ls_Res.result.data;

          // Busca a posição no array da opção selecionada na grade
          let li_Item = await this._array_contabil.findIndex((val) => (val.cod_empreendimento      == lo_Item.cod_empreendimento &&
                                                                       val.cod_cont_planoconta_exp == lo_Item.cod_cont_planoconta_exp));
          
          // Seta de volta o valor que está no banco quando o usuario clica em cancelar, de forma reativa
          Vue.set(this._array_contabil, li_Item, lo_Item);

        }else {
          this.store_site.alert_cor     = "#A00000";
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg     = ls_Res.errors.trim();
          this.store_site.alert         = true;
        }
        this.loading = false;
      }
      this.store_Empreendimento.dialogContabilEdicao = false;
    },
  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif !important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.btn {
  width: 100px;
}
</style>