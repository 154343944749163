<template>
  <div id="DadosBasicosModal">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="justify-space-between py-2 px-3">
        <span class="title-page body-1">Dados Básicos</span>
          <v-btn
          @click="store_Empreendimento.dialogDadosBasicos = false"
          icon
          dark
          color="#F2F6F7"
          >
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
      </v-card-title>

      <v-card-text class="wrapper pt-1 pb-10">
        <v-container>
          <v-row>
            <v-col class="px-0">
              <v-form 
              ref="form"
              v-model="valid">

                <v-text-field
                v-model="dados.codigo"
                class="mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Código"
                placeholder="Código"
                filled
                required
                dense
                :rules="codigoRules"
                ></v-text-field>

                <v-text-field
                v-model="dados.empreendimento"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Empreendimento"
                placeholder="Empreendimento"
                filled
                required
                dense
                :rules="empreendimentoRules"
                ></v-text-field>

                <v-select
                label="Situação"
                v-model="dados.situacao"
                class="mt-n2"
                filled
                dense
                background-color="#FFF"
                :items="situacoes"
                item-text="name"
                :rules="situacaoRules"
                ></v-select>

                <v-autocomplete
                v-model="dados.adm"
                class="mt-n2 mb-0"
                background-color="#FFF"
                :items="adms"
                label="Administradora"
                filled
                return-object
                required
                dense
                :rules="admRules"
                >
                  <template v-slot:append-outer>
                    <v-icon color="primary"
                    >mdi-plus-circle</v-icon>
                  </template>
                </v-autocomplete>

                <v-text-field
                v-model="dados.area"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Área total"
                placeholder="Área total"
                filled
                required
                dense
                :rules="areaRules"
                ></v-text-field>

                <v-autocomplete
                v-model="dados.municipio"
                class="mt-n2 mb-0"
                background-color="#FFF"
                :items="municipios"
                label="Município"
                filled
                return-object
                required
                dense
                :rules="municipioRules"
                >
                  <template v-slot:append-outer>
                    <v-icon color="primary"
                    >mdi-plus-circle</v-icon>
                  </template>
                </v-autocomplete>

                <v-select
                label="Uf"
                v-model="dados.uf"
                class="mt-n2"
                filled
                dense
                background-color="#FFF"
                :items="arrayUF"
                item-text="name"
                ></v-select>

                <v-autocomplete
                v-model="dados.tipo"
                class="mt-n2 mb-0"
                background-color="#FFF"
                :items="tipos"
                label="Tipo"
                filled
                return-object
                required
                dense
                :rules="tipoRules"
                >
                  <template v-slot:append-outer>
                    <v-icon color="primary"
                    >mdi-plus-circle</v-icon>
                  </template>
                </v-autocomplete>

                <v-text-field
                v-model="dados.matricula"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Nº matrícula"
                placeholder="Nº matrícula"
                filled
                required
                dense
                :rules="numeroRules"
                ></v-text-field>

                <v-select
                label="Data matrícula"
                v-model="dados.data_matricula"
                class="mt-n2"
                filled
                dense
                background-color="#FFF"
                :items="datas"
                item-text="name"
                :rules="dataMatriculaRules"
                ></v-select>

                <v-autocomplete
                v-model="dados.cartorio"
                class="mt-n2 mb-0"
                background-color="#FFF"
                :items="cartorios"
                label="Cartório"
                filled
                return-object
                required
                dense
                :rules="cartorioRules"
                >
                  <template v-slot:append-outer>
                    <v-icon color="primary"
                    >mdi-plus-circle</v-icon>
                  </template>
                </v-autocomplete>

                <v-textarea
                v-model="dados.observacao"
                rows="3"
                class="mt-n2"
                background-color="#FFF"
                label="Observação"
                placeholder="Observação"
                filled
                dense
                ></v-textarea>

              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
        @click="store_Empreendimento.dialogDadosBasicos = false"
        class="mr-4 btn caption font-weight-medium"
        color="primary"
        text
        >
          Cancelar
        </v-btn>

        <v-btn
        :disabled="!valid"
        @click="validate()"
        class="btn white--text caption font-weight-medium"
        color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import { COR_PRINCIPAL, arrayUF } from "../../../services/constantes";
import store_Empreendimento from "../store_Empreendimento";

export default {
  name: "DadosBasicosModal",

  components: {
  },

  data() {
    return {

      COR_PRINCIPAL     : COR_PRINCIPAL,
      store_Empreendimento      : store_Empreendimento,
      arrayUF                   : arrayUF,

      dados: {
        codigo: null,
        empreendimento: "",
        situacao: null,
        adm: null,
        area: "",
        municipio: null,
        uf: null,
        tipo: null,
        matricula: "",
        data: null,
        cartorio: null,
        observacao: "",
      },

      /* SELECT SITUAÇÃO */
      situacoes: [
        "Situação1", "Situação2", "Situação3",
      ],

      /* SELECT ADMINISTRADORAS */
      adms: [
        "Administradora1", "Administradora2", "Administradora3",
      ],

      /* SELECT MUNICIPIOS */
      municipios: [
        "Franca", "Cristais Paulista", "Restinga",
      ],
      
      /* SELECT TIPOS */
      tipos: [
        "Tipo1", "Tipo2", "Tipo3",
      ],

      /* SELECT DATA MATRICULA */
      datas: [
         "14/02/2022", "15/02/2022", "16/02/2022" 
      ],

      /* SELECT CARTÓRIOS*/
      cartorios: [
        "Cartório1", "Cartório2", "Cartório3",
      ],

      valid: true,
      form: false,

      empreendimentoRules: [
        (value) => !!value || "O empreendimento é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O empreendimento deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O empreendimento deve ter menos do que 60 caracteres",
      ],
      areaRules: [
        (value) => !!value || "A área é obrigatória",
        (value) =>
          (value && value.length >= 3) ||
          "A área deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "A área deve ter menos do que 60 caracteres",
      ],
      codigoRules: [
        (value) => !!value || "O código é obrigatório",
        (value) => /^\d+$/.test(value) || 'O código não é válido',
        (value) =>
          (value && value.length <= 10) ||
          "O código deve ter menos do que 10 caracteres",
      ],
      numeroRules: [
        (value) => !!value || "O número é obrigatório",
        (value) => /^\d+$/.test(value) || 'O número não é válido',
        (value) =>
          (value && value.length <= 10) ||
          "O número deve ter menos do que 10 caracteres",
      ],
      admRules: [
        (value) => !!value || "A administradora é obrigatória",
      ],
      situacaoRules: [
        (value) => !!value || "A situação é obrigatória",
      ],
      municipioRules: [
        (value) => !!value || "O município é obrigatório",
      ],
      ufRules: [
        (value) => !!value || "A uf é obrigatória",
      ],
      tipoRules: [
        (value) => !!value || "O tipo é obrigatório",
      ],
      dataMatriculaRules: [
        (value) => !!value || "A data é obrigatória",
      ],
      cartorioRules: [
        (value) => !!value || "O cartório é obrigatório",
      ],
    };
  },

  methods: {
    validate () {
      this.store_Empreendimento.docDadosBasicos = 1;
      this.store_Empreendimento.dialogDadosBasicos = false;
      this.$refs.form.validate();
    },
  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  height: 460px;
  overflow-y: auto;
}

/* ---------- BARRA DE SCROLL ---------- */
.wrapper::-webkit-scrollbar {
  width: 5px;
}

.wrapper::-webkit-scrollbar-button {
  padding: 1px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
  height: 10px!important;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

.btn {
  width: 100px;
}
</style>