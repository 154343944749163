<template>
  <div id="QuadrasModal">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="justify-space-between py-2 px-3">
        <span class="title-page body-1">Quadras</span>
          <v-btn
          @click="store_Empreendimento.dialogQuadras = false"
          icon
          dark
          color="#F2F6F7"
          >
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
      </v-card-title>

      <v-card-text class="pt-1 pb-10">
        <v-container>
          <v-row>
            <v-col class="px-0">
              <v-form 
              ref="form"
              v-model="valid">

                <v-text-field
                v-model="dados.nome_quadra"
                class="mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Nome da quadra"
                placeholder="Nome da quadra"
                filled
                autofocus
                required
                dense
                :rules="nomeRules"
                ></v-text-field>

                <v-text-field
                v-model="dados.qtde_lotes"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Quantidade de lotes"
                placeholder="Quantidade de lotes"
                filled
                required
                dense
                :rules="numeroRules"
                ></v-text-field>

              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
        @click="store_Empreendimento.dialogQuadras = false"
        class="mr-4 btn caption font-weight-medium"
        color="primary"
        text
        >
          Cancelar
        </v-btn>

        <v-btn
        :disabled="!valid"
        @click="validate()"
        class="btn white--text caption font-weight-medium"
        color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import { COR_PRINCIPAL, arrayUF } from "../../../services/constantes";
import store_Empreendimento from "../store_Empreendimento";

export default {
  name: "QuadrasModal",

  components: {
  },

  data() {
    return {

      COR_PRINCIPAL     : COR_PRINCIPAL,
      store_Empreendimento      : store_Empreendimento,

      dados: {
        nome_quadra: "",
        qtde_lotes: "",
      },

      valid: true,
      form: false,

      nomeRules: [
        (value) => !!value || "O nome é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O nome deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O nome deve ter menos do que 60 caracteres",
      ],
      numeroRules: [
        (value) => !!value || "A quantidade é obrigatória",
        (value) => /^\d+$/.test(value) || 'A quantidade não é válida',
        (value) =>
          (value && value.length <= 10) ||
          "A quantidade deve ter menos do que 10 caracteres",
      ],
    };
  },

  methods: {
    validate () {
      this.store_Empreendimento.dialogQuadras = false;
      this.$refs.form.validate();
    },
  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.btn {
  width: 100px;
}
</style>