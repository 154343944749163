<template>
  <div id="ContabilModal">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color': COR_PRINCIPAL }" class="justify-space-between py-2 px-3">
        <span class="title-page body-1">Contábil</span>
        <v-btn @click="store_Empreendimento.dialogContabil = false" icon dark color="#F2F6F7">
          <v-icon large class="title">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-1 pb-10">
        <v-container>
          <v-row>
            <v-col class="px-0">
              <v-form ref="form" v-model="valid">

                <v-text-field v-model="codigo_contabil_aux" class="mb-0" light loader-height="1" background-color="#FFF"
                  label="Código contábil empreendimento" placeholder="Código contábil empreendimento" filled required
                  dense :rules="numeroRules"></v-text-field>

              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn @click="store_Empreendimento.dialogContabil = false" class="mr-4 btn caption font-weight-medium"
          color="primary" text>
          Cancelar
        </v-btn>

        <v-btn :loading="loading" :disabled="!valid" @click="validate()"
          class="btn white--text caption font-weight-medium" color="primary accent-4">
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import { COR_PRINCIPAL } from "../../../services/constantes";
import store_Empreendimento from "../store_Empreendimento";
import store_site from "../../../store/store_site";

export default {
  name: "ContabilModal",

  props: {
    _codigo_contabil : {
      type : String
    }
  },

  components: {
  },

  data() {
    return {
      store_Empreendimento: store_Empreendimento,

      COR_PRINCIPAL      : COR_PRINCIPAL,
      codigo_contabil_aux: this._codigo_contabil,
      loading            : false,
      store_site         : store_site,
      contabil           : {},
      valid              : true,
      form               : false,

      numeroRules: [
        (value) => !!value || "O código é obrigatório",
        (value) => /^\d+$/.test(value) || 'O código não é válido',
        (value) =>
          (value && value.length <= 10) ||
          "O código deve ter menos do que 10 caracteres",
      ],
    };
  },

  methods: {

    async validate() {
      const lb_valido = this.$refs.form.validate();

      if (lb_valido) {

        this.contabil.cod_empreendimento = this.$route.params.cod_empreendimento;
        this.contabil.codigo_contabil    = this.codigo_contabil_aux

        var lo_JSON = {
          ...this.contabil,
        };

        var ls_Res;
        this.loading = true;

        ls_Res = await this.store_Empreendimento.EmpreendimentoPut(lo_JSON);

        if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
          this.store_site.alert_cor     = "#00A000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg     = ls_Res.result.result.trim();
          this.store_site.alert         = true;

          this.store_Empreendimento.empreendimento_edicao.codigo_contabil = this.codigo_contabil_aux;

        } else {
          this.store_site.alert_cor     = "#A00000";
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg     = ls_Res.errors.trim();
          this.store_site.alert         = true;
        }
        this.loading = false;
      }
      this.store_Empreendimento.dialogContabil = false
    },
  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif !important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.btn {
  width: 100px;
}
</style>